<template lang="pug">
    .block
        .management-layouts__content
            ManagementMedia
        .management-layouts__tw
            ManagementSocmed
</template>

<script>
import ManagementSocmed from '@/pages/management/Socmed';
import ManagementMedia from '@/pages/management/Media';
export default {
    name: 'ManagementPageIndex',
    components: {
        ManagementSocmed,
        ManagementMedia,
    },
};
</script>
